<template>
    <div class="hot-article" @click="goToCollectArticle">
        <div class="info">
            <div class="head"><span class="type">{{type}}</span><span class="collect-num">已有 {{collectNum}} 人收藏 ></span></div>
            <div class="intro">
                {{intro}}
            </div>
        </div>
        <div class="img">
            <img :src="img"  alt=""/>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        id:{
            type:Number,
            required:true
        },
        type:{
            type:String,
            requeired:true
        },
        collectNum:{
            type:Number,
            required:true
        },
        intro:{
            type:String,
            required:true
        },
        img:{
            type:String,
            required:true
        }
    },
    methods:{
        goToCollectArticle(){
            this.$router.push({path:'/collectArticle', query: {
                id: this.id
            }});
        }
    }
}
</script>

<style scoped>
    .hot-article{
        background-color: #fff;
    }
    .hot-article .info{
        height: 130px;
        padding: 30px 22px;
        font-size: 14px;
        color: #8a97a0;
    }
    
    .hot-article .info .intro{
      word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height:24px;
    }
    .hot-article .info .head{
         width: 100%;
         position: relative;
         padding-bottom:30px ;
    }
    .hot-article .info .head .type{
        font-size:18px;
        font-weight:600;
        color:rgba(0,0,0,1);
    }
    .hot-article .info .head .collect-num{
         position: absolute;
         right: 0px;
    }
    .hot-article .img{
        height: 200px;
    }
    .hot-article img{
        height: 100%;
        width: 100%;
    }
</style>