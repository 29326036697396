<template>
  <div class="ExploreColumnCard ExploreHomePage-columnCard">
    <a
      class="ExploreColumnCard-avatar"
     href="javaScript:void(0)"
      rel="noreferrer noopener"
    >
      <img :src="img" />
    </a>
    <a
      class="ExploreColumnCard-title"
      rel="noreferrer noopener"
    >{{name}}</a>
    <div class="ExploreColumnCard-count">
      <span>{{focusNum}} 关注</span>
      <span>{{articleNum}} 文章</span>
    </div>
    <div class="ExploreColumnCard-intro">{{intro}}</div>
    <button class="ExploreColumnCard-entryButton" @click="goToUserDetail">展开阅读</button>
  </div>
</template>

<script>
export default {
  props:{
    id:{
      type:Number,
      required:true
    },
    img:{
      type:String,
      required:true
    },
    name:{
      type:String,
      required:true
    },
    focusNum:{
      type:Number,
      required:true
    },
    articleNum:{
      type:Number,
      required:true
    },
    intro:{
      type:String,
      required:true
    }
  },
  methods:{
      goToUserDetail(){
          this.$router.push({path:'/author', query: {
              id: this.id
          }});
      }
  }
};
</script>

<style scoped>
    .ExploreColumnCard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 28px 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 235px;
    height: 313px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(26,26,26,.1);
    box-shadow: 0 1px 3px 0 rgba(26,26,26,.1);
    background-color: #fff;
}
.ExploreHomePage-columnCard {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
}
a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.ExploreColumnCard-avatar {
    display: block;
    width: 80px;
    height: 80px;
}

.ExploreColumnCard-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.ExploreColumnCard-title {
    margin-top: 20px;
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    max-width: 187px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-synthesis: style;
}
.ExploreColumnCard-count {
    margin-top: 4px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #999;
}
.ExploreColumnCard-count {
    margin-top: 4px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #999;
}
.ExploreColumnCard-count span+span:before {
    content: "\B7";
    margin: 0 3px;
}
.ExploreColumnCard-intro {
    margin-top: 20px;
    height: 40px;
    line-height: 20px;
    font-size: 14px;
    color: #444;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ExploreColumnCard-entryButton {
    margin-top: 20px;
    padding: 0 16px;
    height: 34px;
    font-size: 14px;
    color: #0084ff;
    background-color: rgba(0,132,255,.08);
    border-radius: 3px;
    font-weight: 600;
    font-synthesis: style;
}
button {
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>
