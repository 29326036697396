<template>
  <!-- 关注页 -->
  <div class="container">
    <div class="content">
      <!-- 最新文章 -->
      <div class="newest-article">
        <div class="title">
          <i></i><span style="margin-left:0"> 最新文章</span>
        </div>
        <div class="items">
          <div
            class="item"
            v-for="theme in newestList"
            :key="theme.id"
            style="height:300px;width:320px"
          >
            <V-OneTheme
              :img="theme.img"
              :intro="theme.intro"
              :id="theme.id"
              :title="theme.title"
              @detail-click="gotoDetail"
              :titleStyle="titleStyle"
              :introStyle="introStyle"
            ></V-OneTheme>
          </div>
        </div>
      </div>
      <!-- 优秀作者 -->
      <div class="excellent-author">
        <div class="title">
          <i class="yxzz-icon"></i>
          <span>优秀作者</span>
        </div>
        <div class="items">
          <div
            class="item"
            v-for="author in excellentAuthorList"
            :key="author.id"
          >
            <V-Author
              :id="author.id"
              :name="author.name"
              :intro="author.intro"
              :img="author.img"
              :focusNum="author.focusNum"
              :articleNum="author.articleNum"
            ></V-Author>
          </div>
        </div>
        <div class="more">
          <a href="javaScript:void(0)" class="more-button"
            ><span class="left-btn"></span></a
          ><span> 查看更多 </span
          ><a href="javaScript:void(0)" class="more-button"
            ><span class="right-btn"></span
          ></a>
        </div>
      </div>
      <!-- 热门收藏 -->
      <div class="hot-collect">
        <div class="title">
          <i class="hotcollect-icon"></i>
          <span>热门收藏</span>
        </div>
        <div class="items">
          <div class="item" v-for="collect in hotCollectList" :key="collect.id">
            <V-HotCollect
              :id="collect.id"
              :type="collect.type"
              :intro="collect.intro"
              :collectNum="collect.collectNum"
              :img="collect.img"
            ></V-HotCollect>
          </div>
        </div>
        <div class="more">
          <a
            href="javaScript:void(0)"
            @click="preHotCollect"
            class="more-button"
            ><span class="left-btn"></span></a
          ><span> 查看更多 </span
          ><a
            href="javaScript:void(0)"
            @click="nextHotCollect"
            class="more-button"
            ><span class="right-btn"></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Author from "@/views/academic-headlines/focus/author/index.vue";
import OneTheme from "@/components/one-theme/type-one/index.vue";
import HotCollect from "@/views/academic-headlines/focus/hot-collect/index.vue";
import { DELETE, QUERY } from "@/services/dao.js";
export default {
  data() {
    return {
      titleStyle: { "font-size": "14px", "line-height": "20px" },
      introStyle: { "font-size": "14px", "line-height": "20px" },
      excellentAuthorPageNum: 0,
      excellentAuthorTotlePage: 0,
      hotCollectPageNum: 0,
      hotCollectTotlePage: 0,
      newestList: [],
      excellentAuthorList: [],
      hotCollectList: [],
    };
  },
  created() {
    //初始化时调用
    this.init();
  },
  methods: {
    init() {
      this.getNewestArticle();
      this.getExcellentAuthor();
      this.getHotCollect();
    },
    //进入最新文章详情
    gotoDetail(id) {
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id,
        },
      });
    },
    //获取最新文章
    async getNewestArticle() {
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: 6, offset: 0, ) {    id    createTime    content    concernNum    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme_id    title    type hotNum  }"
      );
      this.newestList.splice(0, this.newestList.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newestList.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          img: info.data.Article[i].img,
          id: info.data.Article[i].id,
        });
      }
    },
    //获取优秀作者
    async getExcellentAuthor() {
      let info = await QUERY(
        "post",
        "",
        "User(limit: 4, offset: " +
          4 * this.excellentAuthorPageNum +
          ") {    id    img    name    concernNum    articleNum    intro  }  User_aggregate {    aggregate {      count    }  }"
      );
      this.excellentAuthorList.splice(0, this.excellentAuthorList.length);
      for (let i = 0; i < info.data.User.length; i++) {
        this.excellentAuthorList.push({
          id: info.data.User[i].id,
          img: info.data.User[i].img,
          name: info.data.User[i].name,
          focusNum: info.data.User[i].concernNum,
          articleNum: info.data.User[i].articleNum,
          intro: info.data.User[i].intro,
        });
      }
      this.excellentAuthorTotlePage = this.totlePage(
        info.data.User_aggregate.aggregate.count
      );
    },
    //获取热门收藏
    async getHotCollect() {
      let info = await QUERY(
        "post",
        "",
        "Sort( limit: 4, offset: " +
          4 * this.hotCollectPageNum +
          ") {    id    collectNum    img    intro    name  }  Sort_aggregate {    aggregate {      count    }  }"
      );
      this.hotCollectList.splice(0, this.hotCollectList.length);
      for (let i = 0; i < info.data.Sort.length; i++) {
        this.hotCollectList.push({
          id: info.data.Sort[i].id,
          img: info.data.Sort[i].img,
          type: info.data.Sort[i].name,
          intro: info.data.Sort[i].intro,
          collectNum: info.data.Sort[i].collectNum,
        });
      }
      this.hotCollectTotlePage = this.totlePage(
        info.data.Sort_aggregate.aggregate.count
      );
    },
    //作者前一页
    preAuthor() {
      if (this.excellentAuthorPageNum - 1 >= 0) {
        this.excellentAuthorPageNum = this.excellentAuthorPageNum - 1;
        this.getExcellentAuthor();
      }
    },
    //作者后一页
    nextAuthor() {
      if (this.excellentAuthorPageNum + 1 < this.excellentAuthorTotlePage) {
        this.excellentAuthorPageNum = this.excellentAuthorPageNum + 1;
        this.getExcellentAuthor();
      }
    },
    //热门收藏分类前一页
    preHotCollect() {
      if (this.hotCollectPageNum - 1 >= 0) {
        this.hotCollectPageNum = this.hotCollectPageNum - 1;
        this.getHotCollect();
      }
    },
    //热门收藏分类后一页
    nextHotCollect() {
      if (this.hotCollectPageNum + 1 < this.hotCollectTotlePage) {
        this.hotCollectPageNum = this.hotCollectPageNum + 1;
        this.getHotCollect();
      }
    },
    totlePage(count) {
      if (count <= 0) {
        return 0;
      }
      let page = 0;
      if (count < 4) {
        return 1;
      }
      if (count % 4 > 0) {
        page = count / 4 + 1;
      } else {
        page = count / 4;
      }
      return page;
    },
  },
  components: {
    "V-Author": Author,
    "V-HotCollect": HotCollect,
    "V-OneTheme": OneTheme,
  },
};
</script>

<style scoped>
.container > .content {
  width: 1024px;
  margin: 0 auto;
}
.items {
  margin-top: 10px;
}
.title * {
  vertical-align: middle;
}
.title {
  margin-top: 50px;
}
.title span {
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
}
.title i,
.items .item {
  margin-left: 20px;
}
.more {
  text-align: center;
  margin-top: 20px;
}
.more > * {
  vertical-align: middle;
}
.more-button {
  display: inline-block;
  padding: 10px 24px;
  background-color: #fff;
  border-radius: 25px;
  color: #8590a6;
  cursor: pointer;
  font-weight: 600;
  font-synthesis: style;
  text-decoration: none;
}
.items .item {
  display: inline-block;
  margin-top: 30px;
}

.excellent-author .items .item {
  width: 235px;
}

.hot-collect .items .item {
  width: 490px;
  border: 1px solid rgba(229, 229, 229, 1);
}
.more > a:hover {
  background-color: rgba(0, 132, 255, 0.08);
}
</style>
