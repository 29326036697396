<template>
  <div class="cccc">
    <div
      class="uppic-downtext"
      v-for="item in list"
      :key="item.id"
      @click="goThemeDetail(item.id)"
    >
      <div class="img">
        <img :src="item.img" alt="" />
      </div>
      <div class="info">
        <div class="title" :style="titleStyle">{{ item.title }}</div>
        <div class="intro"  :style="introStyle">
          <span v-if="item.intro.length>=100">{{ item.intro.substring(0,100)+"..." }}</span>
          <span v-if="item.intro.length<100">{{ item.intro }}</span>
          <!-- <span >{{ item.intro }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: false,
    },
    titleStyle: {
      type: Object,
      required: false,
    },
    introStyle: {
      type: Object,
      required: false,
    },
  },
  created() {
    console.log("list： ", this.list);
    console.log("titleStyle： ", this.titleStyle);
    console.log("introStyle： ", this.introStyle);
  },
  methods: {
    //打开主题详情
    goThemeDetail(id) {
      this.$emit("detail-click", id);
    },
  },
};
</script>

<style scoped>
.cccc {
  width: 100%;
  /* display: inline-block; */
  /* border: 1px solid firebrick; */
  margin: auto;
}
.uppic-downtext {
  display: inline-block;
  width: 45%;
  /* height: 50%; */
  vertical-align: top;
  /* border: 1px solid red; */
  margin-right: 4%;
}
.uppic-downtext .img {
  height: 50%;
}
.uppic-downtext .img img {
  height: 100%;
  width: 100%;
}

.uppic-downtext .info {
  height: 50%;
  margin-top: 13px;
  text-align: left;
  display: inline-block;
}
.uppic-downtext .title {
  font-size: 18px;
  color: #31424e;
  font-weight: bold;
  display: inline-block;
}
.uppic-downtext .intro {
  font-size: 14px;
  font-weight: 300;
  color: #8a97a0;
  line-height: 30px;
  display: inline-block;
  /* border: 1px solid red; */
} /**/
</style>